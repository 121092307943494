<template>
  <v-row class="ma-2">
    <v-col cols="12" v-if="user && user.role_id === 1">
      <v-container fluid grid-list-md px-0 py-0>
        <v-row>
          <v-col cols="12" lg="3" md="4" sm="12" xs="12" v-for="(item, key) in dashboardItems" :key="key">
            <dashboard-widget :widget-items="item"/>
          </v-col>
        </v-row>
        <v-row class="justify-space-between mt-5 mb-1">
          <v-spacer/>
          <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
              rounded
              dense
              color="primary"
              @change="onChangeDateHandler"
          >
            <v-btn value="today">
              Today
            </v-btn>
            <v-btn value="yesterday">
              Yesterday
            </v-btn>
            <v-btn value="this_week">
              Week
            </v-btn>
            <v-btn value="this_month">
              Month
            </v-btn>
            <v-btn value="this_year">
              Year
            </v-btn>
          </v-btn-toggle>
        </v-row>
        <v-row class="justify-space-between">
          <v-col cols="12" md="3" v-for="(item, key) in dynamicDashItems" :key="key">
            <dashboard-widget :widget-items="item"/>
          </v-col>
          <v-col cols="12" md="12">
            <invoice-chart :selected-date="selectedDate" />
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col cols="12" v-else>
      <h1>Permission Denied</h1>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from "vuex";
import DashboardWidget from "../components/widget/DashboardWidget";
import utility from "../helper/utility"
import moment from "moment";
import InvoiceChart from "../components/chart/InvoiceChart";
export default {
  name: "SummaryReports",
  components: {InvoiceChart, DashboardWidget},
  data() {
    return {
      dashboardItems: [
        /*{
          title: 'Sites',
          type: 'sites',
          value: 0,
          icon: 'mdi-web',
          color: '#5253a2',
          action: '/sites'
        },
        {
          title: 'Categories',
          type: 'category',
          value: 0,
          icon: 'mdi-application-export',
          color: '#5253a2',
          action: '/niche-categories'
        },*/
        {
          title: 'Resellers',
          type: 'reseller',
          value: 0,
          icon: 'mdi-account-group',
          color: '#5253a2',
          action: '/invoices'
        },
        {
          title: 'Users',
          type: 'users',
          value: 0,
          icon: 'mdi-account-cowboy-hat',
          color: '#5253a2',
          action: '/users'
        },
        {
          title: 'Invoices',
          type: 'invoices',
          value: 0,
          icon: 'mdi-book-open-variant',
          color: '#5253a2',
          action: '/invoices'
        },
        {
          title: 'Trash Invoices',
          type: 'trashInvoices',
          value: 0,
          icon: 'mdi-delete',
          color: 'red',
          action: '/invoices'
        },
        {
          title: 'A/C Accepted',
          type: 'totalAccepted',
          value: 0,
          icon: 'mdi-cash-multiple',
          color: '#5253a2',
          action: '/sites'
        },
        {
          title: 'A/C Receivable',
          type: 'totalReceivable',
          value: 0,
          icon: 'mdi-clipboard-arrow-down-outline',
          color: '#5253a2',
          action: '/invoices'
        }
      ],
      toggle_exclusive: 'today',
      chartTitle: '',
      selectedDate: {
        startDate: moment().startOf('days').format('YYYY-MM-DD'),
        endDate: moment().endOf('days').format('YYYY-MM-DD'),
        groupBy: 'd'
      },
    }
  },
  computed: {
    ...mapState({
      dynamicWidgets: state => state.dynamicWidgets,
      user: state => state.auth.user
    }),
    dynamicDashItems() {
      const cInvoice =  this.dynamicWidgets.find((item) => item.type === 'totalCreated')
      const pInvoice =  this.dynamicWidgets.find((item) => item.type === 'totalPaid')
      const order =  this.dynamicWidgets.find((item) => item.type === 'totalOrder')
      const credit =  this.dynamicWidgets.find((item) => item.type === 'totalCredit')

      return [
        {
          title: 'Cr. Invoices',
          type: 'totalCreated',
          value: {
            key: cInvoice ? cInvoice.total : 0,
            amount: cInvoice ? cInvoice.amount : 0.00
          },
          icon: 'mdi-book-open-variant',
          color: '#5253a2',
          action: '/invoices'
        },
        {
          title: 'Paid Invoices',
          type: 'totalPaid',
          value: {
            key: pInvoice ? pInvoice.total : 0,
            amount: pInvoice ? pInvoice.amount : 0.00
          },
          icon: 'mdi-cash-multiple',
          color: '#5253a2',
          action: '/invoices'
        },
        {
          title: 'Total Orders',
          type: 'totalOrder',
          value: {
            key: order ? order.total : 0,
            amount: order ? order.amount : 0.00
          },
          icon: 'mdi-cart',
          color: '#5253a2',
          action: '/orders'
        },
        {
          title: 'Total Credits',
          type: 'totalCredit',
          value: {
            key: credit ? credit.total : 0,
            amount: credit ? credit.amount : 0.00
          },
          icon: 'mdi-cart-arrow-right',
          color: '#5253a2',
          action: '/payments'
        }
      ]
    }
  },
  mounted() {
    this.dSummaryHandler();
  },
  methods: {
   dSummaryHandler() {
      this.$store.dispatch('loadDSummaries')
       .then(({data})=> {
         const {totalSite, totalCategory, totalReseller, trashInvoice, totalUser,  totalInvoice, totalAccepted, totalReceivable} = data;
         this.dashboardItems = this.dashboardItems.map((item)=> {
           switch (item.type) {
             case 'sites':
               item.value = totalSite;
               return item;
             case 'category':
               item.value = totalCategory;
               return item;
             case 'invoices':
               item.value = totalInvoice;
               return item;
             case 'reseller':
               item.value = totalReseller;
               return item;
             case 'trashInvoices':
               item.value = trashInvoice;
               return item;
             case 'users':
               item.value = totalUser;
               return item;
             case 'totalAccepted':
               item.value = `$${utility.abbreviateNumber(Number(totalAccepted))}`;
               return item;
             case 'totalReceivable':
               item.value = `$${utility.abbreviateNumber(Number(totalReceivable))}`;
               return item;
             default:
               return  item

           }
         })
       })
       .catch((error)=> {
         console.log(error);
       })
    },
    onChangeDateHandler(dateType) {
      let startDate = moment();
      let endDate = moment();
      let groupBy = 'd';

      switch (dateType) {
        case 'yesterday':
          startDate = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
          endDate = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD');
          break;
        case 'today':
          startDate = moment().startOf('day').format('YYYY-MM-DD');
          endDate = moment().endOf('day').format('YYYY-MM-DD');
          break;
        case 'this_week':
          startDate = moment().startOf('isoweek').format('YYYY-MM-DD');
          endDate = moment().endOf('isoweek').format('YYYY-MM-DD');
          break;
        case 'this_month':
          startDate = moment().startOf('month').format('YYYY-MM-DD');
          endDate = moment().endOf('month').format('YYYY-MM-DD');
          break;
        case 'this_year':
          startDate = moment().startOf('year').format('YYYY-MM-DD');
          endDate = moment().endOf('year').format('YYYY-MM-DD');
          groupBy = 'm';
          break;
        default:
          break;
      }

      this.selectedDate = {startDate, endDate, groupBy};
    }
  }
}
</script>

